.sign-in-outer-root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70%;

  .sign-in-root {
    display: flex;
    justify-content: space-around;
    height: 90%;

    .sign-in-field-container {
      background: white;
      display: inherit;
      flex-direction: column;
      border: solid;
    }
  }
}