.editor-root {
  display: flex;
  height: calc(100% - 25px);

  .video-preview-container {
    display: inherit;
    align-items: center;
    flex-direction: column;
    flex: 60%;

    .player-outline {
      margin: 15px;
      width: calc(100% - 15px);
    }

    .video-tool-bar-container {
      width: 100%;
      height: 100%;
    }
  }
}