.account-page-root {
  width: 100%;
  height: 100%;
  display: flex;

  .account-page-controls {
    height: 80px;
  }

  .account-project-list-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}