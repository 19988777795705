.url-field {
  text-align: left;
  margin: 0 40px 0 15px;
  text-transform: uppercase;


  .processing-message {
    margin-left: 20px;
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
    color: #d24c4c;
  }

  .field-source-value {
    padding-right: 0;
  }

  .video-details-input-field {
    background-color: #1B1919;
    width: 100%;
    border: solid 1px #767171;
    border-radius: 7px;

    .details-input-upload-button {
      height: 40px;
      margin-right: 0px;
      z-index: 1;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .details-input-upload-button-icon {
        font-size: 20px;
        width: 100%;
        height: 25px;
      }
    }

    input {
      color: #9B9797;

      &.Mui-disabled {
        -webkit-text-fill-color: inherit;
      }
    }
  }
}