.project-list-root {
  height: calc(100% - 130px);
  background: rgb(97, 90, 90) !important;
  padding: 0px 0px 0 0px;

  &.MuiTableContainer-root {
    background-color: transparent;
  }

  .project-list-table {
    height: 100%;

    // Added styles for fixed table head and scrollable table body
    .project-thumbnail-head {
      width: 5%;
    }

    .head-tile {
      font-size: 25px;
      text-align: left;
      color: black !important;
    }

    .project-title-head {
      width: 70%;
    }

    .MuiTableContainer-root {
      max-height: 400px; // Adjust this value as needed
    }

    .project-project-id-head {
      width: 20%;
    }

    .project-project-duration-head {
      width: 10%;
    }

    .MuiTableHead-root {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white; // To ensure the background is not transparent
    }

    .MuiTableBody-root {
      overflow-y: auto;
    }

    .MuiTableRow-root {
      display: table;
      width: 100%;
      table-layout: fixed; // Ensure the table layout is fixed for consistent cell widths

      &.table-row:hover {
        background-color: #615A5A;
        cursor: pointer;
      }
    }

    .MuiTableCell-root {
      color: #C1BCBC;
    }


    .project-list-body {
      overflow: scroll;
      width: 100%;
      height: 100%;

      .progress-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .progress-inner-wrapper {
          display: flex;
          justify-content: space-around;

          .project-list-loading-spinner {
            svg {
              circle {
                stroke: red;
              }
            }
          }
        }
      }

      .tablerow-inner-wrapper {
        display: flex;
      }

      .project-tile-thumbnail {
        img {
          height: 90%;
          margin: 4px;
        }

        // flex-grow: 0.5;
        width: 5%;
      }

      .project-list-title-and-services {

        .project-list-title {
          font-size: 18px;
          font-weight: bold;
        }

        .project-list-services {
          margin-top: 8px;
          max-width: fit-content;
          padding: 2px 5px 2px 5px;
        }

        width: 70%;
        text-align: left;

        h4 {
          margin-top: 5px;
        }
      }

      .project-list-project-id,
      .project-list-project-duration {

        display: flex;
        align-items: center;
        width: 10%
      }

      .project-list-project-id {
        width: 20%;
      }
    }
  }

}