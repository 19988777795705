.account-project-list-container-root {
  height: 200px;
  flex: 85%;
  height: calc(100% - 80px);
  padding: 0 20px 0 20px;

  .heading-and-search-bar {
    display: flex;
    justify-content: space-between;
    height: calc(100% - (100% - 80px));

    .search-bar-outer-wrapper {
      height: 100%;
      width: 100%;
      max-width: 400px;
    }

    .project-list-heading {
      text-align: left;
    }
  }
}