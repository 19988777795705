.workflow-helper {
  height: calc(100% - 5px);

  .step-headings {
    display: flex;

    .step {
      color: rgba(193, 188, 188, 0.4);

      &.active {
        color: inherit;
      }
    }
  }
}

h2 {
  margin-left: 20px;
}