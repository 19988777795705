.video-details-input-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #161515;
  padding: 15px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  .details-section { 
    &.description-section {
      textarea {
        color: #9B9797;
      }
    }
    &.source-section {
      .source-field-label {
        text-align: left;
        margin-left: 15px;
        text-transform: uppercase;

        .transcoding-message {
          margin-left: 20px;
          font-weight: 500;
          font-size: 12px;
          font-style: italic;
          color: #d24c4c;
        }
      }
      .video-details-input-fields-source {
        padding-right: 0;
      }
    }

    .video-details-input-field {
      background-color: #1B1919;
      width: 100%;
      border: solid 1px #767171;
      border-radius: 7px;

      .details-input-upload-button {
          height: 56px;
          margin-right: 0px;
          z-index: 1;
          border-radius: 6px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

        .details-input-upload-button-icon {
          font-size: 20px;
          width: 100%;
          height: 100%;
        }
      }

      input {
        color: #9B9797;
        
        &.Mui-disabled {
          -webkit-text-fill-color: inherit;
        }
      }
    }

    &.timing-section {
      display: flex;
      .video-details-input-field {
        margin-right: 5px;
      }

      .timing-section-field {
        width: 50%;
      }
    }

    .disabled-field {
      border: none;
      background-color: #423d3d;
    }
  }
  label, h2, h3 {
    text-align: left;
  }
}