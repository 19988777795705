.moi-container {
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 100px);

  .select {
    &.model-select {}

    &.service-select {}
  }
}

.prompt-input {
  // add specific styles for the prompt input here
}

.submit-button {
  // add specific styles for the submit button here
}

.response-title {
  // add styles for the response title
}

.response-text {
  // add styles for the response text
  overflow: scroll;
}