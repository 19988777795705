.left-panel-tab-container {
  height: 5%;
  text-align: left;
  display: flex;
  justify-content: space-between;

  .panel-tab {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #575555;

    &:hover {
      background-color: #979797;
      color: black
    }
  }

  .active-tab {
    background-color: #161515;
  }

  .css-zqcytf-MuiButtonGroup-root {
    box-shadow: none;

    .MuiButtonGroup-grouped:not(:last-of-type) {
      box-shadow: none;
      border-right: none;
    }
  }

  .save-button-container {
    display: flex;
    margin-bottom: 3px;

    .save-button {
      font-size: 20px;
      background-color: rgb(22, 117, 22);
    }

    .save-icon {
      font-size: 20px;
    }
  }
}