.upload-video {
  /* Your existing styles */
  height: 100%;
  display: flex;
  flex-direction: column;

  .upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: center;

    .button-and-drag-area {
      height: 50%;
      border: dotted;
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 6px;
      background-color: rgba(255, 255, 255, 0.2);
      cursor: grab;
      border-radius: 8px;
      min-width: 675px;

      .complete-icon {
        display: flex;
        justify-content: space-around;
        font-size: 10em;

        svg {
          fill: #61af49;
        }
      }

      .complete-label {
        display: flex;
        justify-content: space-around;
        font-size: 35px;
        font-weight: bold;
      }

      .video-info-area {
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .video-title {
          font-size: 40px;
          font-weight: bold;
        }
      }

      .button-and-prompt-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        text-align: center;

        .drag-area-wrapper {
          display: flex;
          justify-content: space-around;

          .drag-drop-area {
            display: flex;
            justify-content: space-around;
            width: 50%;
            width: 100%;
            text-align: center;
            padding: 6px;
          }
        }

        .button-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }

    }
  }

  &.dragging .drag-drop-area {
    /* Visual cues for dragging, e.g., border, background */
    border: 2px dashed #000;
    background-color: rgba(0, 0, 0, 0.1);
  }
}