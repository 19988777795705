.metadata-tab-container {
  height: inherit;
  padding-top: 13px;
  padding: 13px 10px 0px 10px;

  .MuiTabs-indicator {
    display: none; // hides border under selected option
  }

  .sub-tab {
    background-color: #575555;
    color: white;

    &.Mui-selected {
      color: white;
      border-bottom: none;
      background-color: #161515;
    }
  }

  .tab-content {
    background-color: #161515;
    height: calc(100% - 37px);

    .sub-tab-container {
      padding: 13px 10px 0px 10px;
      display: flex;
      flex-direction: column;
    }
  }

  .metadata-display-container {
    display: flex;
    flex-direction: column;
    width: 100%
  }
}

.metadata-pre {
  background-color: #1a1919;
  // border: 1px solid #ddd;
  // padding: 10px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  overflow-x: auto;
}