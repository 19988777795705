.metadata-type-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 12px);
  padding: 13px 10px 0px 10px;

  .metadata-type-info-block {
    border: solid 1px;
    height: 200px;
    margin-bottom: 8px;
    width: 100%;
  }

  .metadata-display-container {
    border: 1px solid #ddd;
    margin-bottom: 10px;

    h4 {
      text-align: left;
      padding-left: 10px;
    }

    .metadata-pre {
      background-color: #2d2828;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: left;
      overflow-x: auto;
      height: 440px;
      margin: 10px;
    }
  }
}