.upload-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .upload-modal-cancel-button-wrapper {
    .upload-modal-cancel-button {
      background-color: #d24c4c;
      margin-top: 12px;
      align-items: end;
      float: right;
    }
  }
}

.file-input {
  margin-top: 20px;
}