.translation-dropdown {
  background-color: #1B1919!important;
  width: 100%;
  color: #c1bcbc !important;
  font-weight: bold !important;
  font-size: 20px !important;
  border: solid 2px  #767171;
  border-radius: 0px !important;

  div:nth-child(1) {
    width: 100%;
    // padding: 0px 0px 0px 11px;
  }
  
  svg, fieldset {
    color: #c1bcbc
    
  }
}

.translation-dropdown-select {
  background-color: transparent;
  border: none;
}

.translation-dropdown-option {
  color: #c1bcbc;
  
}