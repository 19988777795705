.copy-services-list-wrapper {
  padding-left: 25px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .copy-services-header {
    text-align: center;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  h2:nth-child(3) {
    margin-top: 50px;
  }

  .copy-services-list {
    padding: 0;

    flex: 40%;
    list-style: none;

    .copy-services-list-item {
      margin-bottom: 20px;

      .coming-soon-label {
        font-style: italic;
      }

      .coming-soon-tag {
        font-size: 10px;
        font-weight: bold;
      }
    }
  }
}