.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    background-color: #fff;
    border: none;
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    // padding: 16px;
    width: 400px;
  
    .header {
      text-align: left;
      margin-bottom: 16px;
    }
    
    .dropdown {
      margin-bottom: 16px;
    }
    
    .language-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
    
      .language-chip {
        margin: 8px;
      }
    }
    .button-container {
      display: flex;
      justify-content: flex-end;
      .translate-button {
        margin-right: 5px;
      }
      
      .cancel-button {
        background-color: #d24c4c;
        color: #fff;
      }
    }
  }
}


.translation-settings-button {
  display: flex;
  justify-content: flex-end;
  width: 0;
  .translation-settings-button-icon {  
    color: #979797;
    cursor: pointer;
  }
}

