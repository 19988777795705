.translation-table {
  height: 85%;

  .translation-table-row {
    .translation-table-cell {
      border: solid 1px #b1929239;
      padding: 16px 16px 0px 16px;

      &.entry-delete-cell {
        width: 0%;
        .translation-entry-delete-button {
          svg {
            font-size: 25px;
            path { 
              fill: #d24c4c
            }
          }
        }
      }
      .translation-text-field {
        background-color: #23202060;
        width: 100%;

        input {
          height: 0.05rem;
          color: #c1bcbc;

          &.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
            -webkit-text-fill-color: inherit;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .translation-table-body {
    overflow: scroll;
    height: 100%;
  }
}

.translation-table-header-row {
  width: 100%;
  
  .translation-table-header-cell {
    width: 50%;
    font-size: 20px;
    color: #c1bcbc;
    border: none;

    &.translation-column {
      justify-content: 50%;
      width: 100%;
      padding-right: 0;

      button {
        float: right;
        padding: 0;
      }
    }

    &.base-language-header-cell {
      border-right: solid 1px;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  position: relative;

  .pagination-arrows {
    display: flex;
    align-items: center;

    .pagination-arrow {
      cursor: pointer;
      font-size: 20px;
      margin: 0 10px;
    }
  }

  .pagination-numbers {
    display: flex;
    align-items: center;

    .pagination-number {
      cursor: pointer;
      padding: 5px;
      margin: 0 5px;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: #ccc;
      }
    }
  }
}

// red: d24c4c