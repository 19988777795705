@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:ital,wght@0,300;0,500;1,300;1,400&display=swap');

.logo-wrapper {
  font-family: 'Roboto Mono', monospace;
  min-height: calc(100% - 45px);
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .logo-inner-wrapper {
    flex: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: center;

    .logo-second-inner-wrapper {
      .logo-slogan-text {
        opacity: 0;
        transition: opacity 1s;
        text-align: center;

        &.visible {
          transition-delay: 1s;
          opacity: 1;
        }
      }

      .logo {
        opacity: 0;
        font-size: 4em;
        text-align: center;
        transition: opacity 1s;
        display: flex;
        align-items: center;

        .logo-icon {
          display: flex;
          margin-right: 10px;
          position: relative;
          top: 4px;

          @media screen and (max-width: 550px) {
            display: none;
          }
        }

        &.visible {
          opacity: 1;
        }
      }

    }
  }
}