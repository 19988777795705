.copy-text-root {
  flex: 70%;
  transition: opacity 1s;
  opacity: 0;
  max-width: 84em;
  text-align: left;
  display: inherit;
  // font-weight: bold;

  @media screen and (max-width: 1600px) {
    flex-direction: column;
    margin-right: 30px;
    margin-left: 30px;
    text-align: center;
  }

  .copy-main-text {
    flex: 1;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-right: solid 1px grey;

    @media screen and (max-width: 1600px) {
      flex: 0;
      border-right: none;
      border-bottom: solid 1px grey;
      margin-right: 0;
      padding-top: 20px;
    }

    .copy-paragraph-wrapper {
      margin-right: 10px;
      text-align: left;

      .block-wrapper {
        display: flex;

        h2 {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-right: 20px;
        }
      }

      a {
        color: #2976D2;
        text-decoration: none;
      }
    }
  }

  &.visible {
    opacity: 1;
    transition-delay: 2s;
  }
}