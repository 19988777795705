.sub-tab-container {
  height: calc(100% - 12px);

  .generate-tab-heading-and-spinner {
    display: flex;
    justify-content: space-between;

    .generate-tab-heading {
      text-align: left;

      .loading-spinner {
        display: flex;
      }
    }
  }

  .form {
    height: calc(100% - 75px);
    overflow: scroll;

    .form-container {
      border: solid 3px #575555;
      padding: 10px;
      border-radius: 4px;
      background-color: #1B1919;
      color: #9B979A;
      margin: 0px 0px 10px 0;

      &.form-optimize-step {
        display: flex;
        justify-content: space-between;

        .step-heading-wrapper {
          max-width: 450px;

          .completed-optimizations {
            text-transform: capitalize;
            font-style: italic;
            margin-bottom: 6px;
            font-size: .9em;
            font-weight: bold;
            text-decoration: underline;

          }

          .optimize-label {
            font-size: .9em;

            a {
              color: #1976D2;
            }
          }
        }

        .right-justified-button-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }

      p {
        font-style: italic;
        font-weight: bold;
      }

      .Mui-disabled {
        color: inherit;
      }

      .generate-metadata-button {
        width: 100%;
        display: flex;
        justify-content: right;

        .Mui-disabled {
          background-color: #575555
        }
      }

      .right-justified-button-wrapper {
        .Mui-disabled {
          background-color: #575555
        }
      }

      .checkboxes {
        .Mui-disabled {
          color: #575555;
        }

        svg {
          path {
            fill: #9B979A;
          }
        }

        .Mui-checked {
          &.Mui-disabled {
            svg {
              path {
                fill: #9B979A;
              }
            }
          }

          svg {
            path {
              fill: #7f7fea;
            }
          }
        }
      }
    }
  }

}