.account-page-sidebar-root {
  border-right: solid 1px;
  height: 100%;
  flex: 15%;
  padding: 0 8px 0 8px;
  background-color: #343232;

  h2 {
    // margin-left: 8px;
  }

  .checkbox-container {
    position: relative;
    /* Make it a positioning context for the overlay */
    padding: 8px;
    background: #615A5A;

    .disabled-overlay {
      display: flex;
      flex-direction: column;
      position: absolute;
      justify-content: space-around;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      /* Black background with .5 opacity */
      z-index: 2;
      /* Ensure it sits above other content, but under any modals/popups */
      cursor: not-allowed;

      .coming-soon-label {
        font-style: italic;
        text-align: center;
      }
    }

    h3 {
      margin-top: 0;
    }

    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: #000;
    }
  }
}