.sample-video-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #C1BCBC;
  border-radius: 10px;

  .preview-container {
    display: flex;
    
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    .language-toggle-buttons {
      display: flex;
      flex-direction: column;
      width: 300px;
      padding-top: 2px;

      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }

    .sample-video {
      max-width: 1280px !important;
      min-width: 300px;

      [data-loaded=false] {
        display: none;
      }

      video {
        max-width: 1280px !important;
        min-width: 300px;
        height: 100%;
      }
    }
  }
} 