.editor-left-panel-root {
  height: 100%;
  min-height: 750px;
  min-width: 500px;
  flex: 30%;
  border: solid 2px #4e4747;
  margin: 0 5px 5px 5px;
  padding: 5px;
  border-radius: 10px;
  background: rgb(43, 43, 43);

  .panel-content-root {
    overflow: scroll;
    height: calc(100% - 45px);
  }

  .translation-text-editor-container {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 94.7%;
    overflow: hidden;
    background-color: #161515;
    display: flex;
    flex-direction: column;
  }
}