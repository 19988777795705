.search-bar-root {
  position: relative;
  width: 100%;
  max-width: 400px; // Adjust based on your UI requirements
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search-bar-container {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    width: 97%;
    height: 50%;

    .search-icon {
      padding: 10px;
      color: rgba(0, 0, 0, 0.54);
    }

    .search-input {
      flex: 1;
      padding-left: 8px;
      height: 80%;
    }
  }

  .search-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1; // Ensure it covers the search input but stays below modal/popups
  }
}